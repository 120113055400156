import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import tw from 'tailwind.macro';

const Wrapper = styled.a`
  width: 100%;
  ${tw`shadow-lg relative no-underline rounded-full px-2 py-4 text-white`};
  background: ${props => props.bg};
  transition: transform 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  &:hover {
    transform: translateY(-5px);
  }
`;

const Text = styled.div`
  ${tw`opacity-75 font-sans text-lg md:text-base`};
  text-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  svg {
    width: 100%;
    margin-right: 13px;
  }
`;

const Button = ({ link, children, bg }) => (
  <Wrapper href={link} target='_blank' rel='noopener noreferrer' bg={bg}>
    <Text>{children}</Text>
  </Wrapper>
);

export default Button;

Button.propTypes = {
  link: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.node
  ]).isRequired,
  bg: PropTypes.string.isRequired
};
