import React from 'react';
import styled from 'styled-components';
import tw from 'tailwind.macro';
import { Parallax } from 'react-spring/renderprops-addons.cjs';

// Components
import Layout from '../components/Layout';
import ProjectCard from '../components/ProjectCard';
import Button from '../components/Button';

// Elements
import Inner from '../elements/Inner';
import { Title, BigTitle, Subtitle } from '../elements/Titles';

// Views
import Hero from '../views/Hero';
import Projects from '../views/Projects';
import About from '../views/About';
import Contact from '../views/Contact';

import avatar from '../images/avatar.jpg';

const ProjectsWrapper = styled.div`
  ${tw`flex flex-wrap justify-between mt-8`};
  display: grid;
  grid-gap: 4rem;
  grid-template-columns: repeat(2, 1fr);
  @media (max-width: 1200px) {
    grid-gap: 3rem;
  }
  @media (max-width: 900px) {
    grid-template-columns: 1fr;
    grid-gap: 2rem;
  }
`;

const AboutHero = styled.div`
  ${tw`flex flex-col lg:flex-row items-center mt-8`};
`;

const Avatar = styled.img`
  ${tw`rounded-full w-32 xl:w-48 shadow-lg h-auto`};
`;

const AboutSub = styled.span`
  ${tw`text-primary pt-12 lg:pt-0 lg:pl-12 text-2xl lg:text-3xl xl:text-4xl`};
`;

const AboutDesc = styled.p`
  ${tw`text-primary text-lg md:text-xl lg:text-2xl font-sans pt-6 md:pt-12 text-justify`};
`;

const ContactText = styled.p`
  ${tw`text-primary font-sans text-xl sm:hidden md:block md:text-2xl lg:text-3xl`};
`;

const ContactsWrapper = styled.div`
  ${tw`flex flex-wrap justify-between mt-8 md:hidden`};
  display: grid;
  grid-gap: 4rem;
  grid-template-columns: repeat(2, 1fr);
  @media (max-width: 1200px) {
    grid-gap: 3rem;
  }
  @media (max-width: 900px) {
    grid-template-columns: 1fr;
    grid-gap: 2rem;
  }
`;

const Footer = styled.footer`
  ${tw`text-center text-grey absolute pin-b p-6 font-sans text-md lg:text-lg`};
`;

const Index = () => (
  <>
    <Layout />
    <Parallax pages={5}>
      <Hero offset={0}>
        <BigTitle>
          Hello, I'm <br /> a gal called Jen!
        </BigTitle>
        <Subtitle>
          I’m a developer who loves making mobile and web applications feel
          alive.
        </Subtitle>
      </Hero>
      <Projects offset={1}>
        <Title>Projects</Title>
        <ProjectsWrapper>
          <ProjectCard
            title='Main St Brewing'
            link='https://github.com/agalcalledjen/msb-winter-2019'
            bg='linear-gradient(to right, #5152a2 0%, #cba3e4 100%)'
          >
            A rewards program mobile app for Main St. Brewing Co. & it includes
            events & beer info.
            <br />
            <br />
            💻 Technologies used: React Native, GraphCool, Apollo Client, Apollo
            Server. 
          </ProjectCard>
          <ProjectCard
            title='fitGO'
            link='https://github.com/agalcalledjen/fitgo'
            bg='linear-gradient(to right, #c4f5e8 0%, #5152a2 100%)'
          >
            A real-time web application that gives users access to personal
            trainers nearby.
            <br />
            <br />
            💻 Technologies used: React, Meteor, & MongoDB.
          </ProjectCard>
          <ProjectCard
            title='Meowtown'
            link='https://github.com/agalcalledjen/Meowtown'
            // bg='linear-gradient(to right, #D585FF 0%, #00FFEE 100%)'
            bg='linear-gradient(to right, #5152a2 0%, #c4f5e8 100%)'
          >
            A sharing economy web-based application for cat items.
            <br />
            <br />
            💻 Technologies used: React, Redux, Material UI, PostgreSQL,
            Express, Apollo, GraphQL.
          </ProjectCard>
          <ProjectCard
            title='Collingwood Neighbourhood House'
            link='https://github.com/agalcalledjen/cnh-fall-2018'
            bg='linear-gradient(to right, #cba3e4 0%, #5152a2 100%)'
          >
            A responsive multi-page website.
            <br />
            <br />
            💻 Technologies used: JavaScript, jQuery, HTML, Sass, PHP. <br />
            💻 Content Management System: WordPress
          </ProjectCard>
        </ProjectsWrapper>
      </Projects>
      <About offset={3}>
        <Title>About</Title>
        <AboutHero>
          <Avatar src={avatar} alt='Jennifer Lam' />
          <AboutSub>Front End / Software Developer</AboutSub>
        </AboutHero>
        <AboutDesc>
          My superpowers include my organizational skills and my attention to
          detail, which along with my technical skills, allow me to turn great
          designs into clean, functional code. <br />
          <br />
          I am interested in opportunities that allow me to build on my existing
          development skills while creating innovative applications that blend
          art and technology seamlessly. I also have a strong eye for design.
          <br />
          <br />
          I’m ready to be part of an awesome and open-minded team in the role of
          front end / software developer.
          <br />
          <br />
          Skills: Teamwork, Front-End Development, Responsive Design,
          JavaScript, React, React Native, jQuery, PHP, SQL, CSS.
        </AboutDesc>
      </About>
      <Contact offset={4}>
        <Inner>
          <Title>Let's connect</Title>
          <ContactText>
            Say <a href='mailto:agalcalledjen@gmail.com'>Hi</a> or find me on
            other platforms:{' '}
            <a href='https://www.linkedin.com/in/agalcalledjen/'>LinkedIn</a> &{' '}
            <a href='https://www.instagram.com/agalcalledjen/'>Instagram</a>
          </ContactText>
          <ContactsWrapper>
            <Button
              link='mailto:agalcalledjen@gmail.com'
              bg='linear-gradient(to right, #cba3e4 0%, #e3d6ea 100%)'
            >
              <i class='fas fa-envelope fa-lg' />
              agalcalledjen@gmail.com
            </Button>
            <Button
              link='https://www.linkedin.com/in/agalcalledjen/'
              bg='linear-gradient(to right, #cba3e4 0%, #e3d6ea 100%)'
            >
              <i class='fab fa-linkedin fa-lg' />
              Jennifer Lam
            </Button>
            <Button
              link='https://github.com/agalcalledjen'
              bg='linear-gradient(to right, #cba3e4 0%, #e3d6ea 100%)'
            >
              <i class='fab fa-github-alt fa-lg' />
              agalcalledjen
            </Button>
          </ContactsWrapper>
        </Inner>
        <Footer>&copy; 2019 agalcalledjen.com</Footer>
      </Contact>
    </Parallax>
  </>
);

export default Index;
