import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import tw from 'tailwind.macro';
import { colors } from '../../tailwind';
import { Divider } from '../elements/Dividers';
import Content from '../elements/Content';
import { UpDown, UpDownWide } from '../styles/animations';
import SVG from '../components/SVG';

const Wrapper = styled.div`
  ${tw`w-full xl:w-2/3`};
`;

const Hero = ({ children, offset }) => (
  <>
    <Divider speed={0.2} offset={offset}>
      <UpDown>
        <SVG
          icon='triangle'
          hiddenMobile
          width={48}
          stroke={colors['primary-lightest']}
          left='10%'
          top='20%'
        />
        <SVG
          icon='hexa'
          width={48}
          stroke={colors['primary-light']}
          left='60%'
          top='70%'
        />
        <SVG
          // icon='box'
          icon='logo'
          width={6}
          fill={colors['grey-lightest']}
          // fill={colors.white}
          left='60%'
          top='15%'
        />
      </UpDown>
      <UpDownWide>
        <SVG
          // icon='arrowUp'
          icon='logo'
          hiddenMobile
          width={16}
          // fill={colors['blue-dark']}
          fill={colors['primary-light']}
          left='80%'
          top='10%'
        />
        <SVG
          icon='triangle'
          width={12}
          stroke={colors.white}
          left='90%'
          top='50%'
        />
        <SVG
          icon='circle'
          width={16}
          fill={colors['grey-lightest']}
          left='70%'
          top='90%'
        />
        <SVG
          icon='triangle'
          width={16}
          stroke={colors.white}
          left='30%'
          top='65%'
        />
        <SVG
          icon='hexa'
          width={16}
          stroke={colors['primary-lightest']}
          left='28%'
          top='15%'
        />
        <SVG icon='circle' width={8} fill={colors.white} left='75%' top='10%' />
        <SVG
          icon='code'
          hiddenMobile
          width={8}
          fill={colors.white}
          left='45%'
          top='10%'
        />
      </UpDownWide>
      <SVG
        icon='circle'
        hiddenMobile
        width={24}
        fill={colors['grey-lightest']}
        left='5%'
        top='70%'
      />
      <SVG icon='circle' width={6} fill={colors.white} left='4%' top='20%' />
      <SVG icon='circle' width={12} fill={colors.white} left='50%' top='60%' />
      <SVG icon='code' width={8} fill={colors.white} left='95%' top='90%' />
      <SVG
        icon='code'
        hiddenMobile
        width={24}
        fill={colors['grey-lightest']}
        left='40%'
        top='80%'
      />
      <SVG
        icon='triangle'
        width={8}
        stroke={colors['grey-lightest']}
        left='25%'
        top='5%'
      />
      <SVG icon='circle' width={64} fill={colors.primary} left='95%' top='5%' />
      <SVG
        // icon='box'
        icon='logo'
        hiddenMobile
        width={64}
        fill={colors.purple}
        left='5%'
        top='90%'
      />
      <SVG
        // icon='box'
        icon='logo'
        width={6}
        fill={colors.white}
        left='10%'
        top='10%'
      />
      <SVG
        // icon='box'
        icon='logo'
        width={12}
        fill={colors.white}
        left='40%'
        top='30%'
      />
      <SVG
        icon='hexa'
        width={10}
        stroke={colors['grey-lightest']}
        left='10%'
        top='54%'
      />
      <SVG
        icon='hexa'
        width={8}
        stroke={colors['grey-lightest']}
        left='80%'
        top='70%'
      />
    </Divider>
    <Content speed={0.4} offset={offset}>
      <Wrapper>{children}</Wrapper>
    </Content>
  </>
);

export default Hero;

Hero.propTypes = {
  children: PropTypes.node.isRequired,
  offset: PropTypes.number.isRequired
};
