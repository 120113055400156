import styled from 'styled-components';
import tw from 'tailwind.macro';
import { rotateAnimation } from '../styles/animations';
import triangle from '../images/triangle.svg';

export const Title = styled.h1`
  ${tw`text-4xl lg:text-4xl font-serif text-primary mb-8 p-2 tracking-wide relative inline-block`};
  text-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  background: -webkit-linear-gradient(125deg, #cba3e4, #5152a2);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  &:before {
    content: '';
    width: 40px;
    height: 40px;
    background: url(${triangle});
    position: absolute;
    background-size: 40px;
    ${rotateAnimation('4s')};
    left: -60px;
    top: 5px;
  }
`;

export const BigTitle = styled.h1`
  ${tw`text-5xl lg:text-6xl lg:tracking-normal font-serif text-primary mb-6 p-4 tracking-wide`};
  text-shadow: 0 5px 35px rgba(255, 255, 255, 0.15);
  background: -webkit-linear-gradient(125deg, #e3d6ea, #cba3e4, #5152a2);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const Subtitle = styled.p`
  ${tw`text-2xl lg:text-4xl font-sans text-primary mt-8 xxl:w-3/4`};
  text-shadow: 0 2px 15px rgba(0, 0, 0, 0.2);
`;
