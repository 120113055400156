import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import tw from 'tailwind.macro';
import { width as twWidth } from '../../tailwind';
import { hidden } from '../styles/utils';

const Wrapper = styled.svg`
  ${tw`absolute`};
  stroke: currentColor;
  ${props => props.hiddenMobile && hidden};
  color: ${props => props.stroke};
  width: ${props => props.svgWidth};
  fill: ${props => props.fill};
  left: ${props => props.left};
  top: ${props => props.top};
`;

const icons = {
  triangle: {
    shape: (
      <polygon
        strokeWidth='1px'
        strokeLinejoin='round'
        strokeMiterlimit='10'
        points='14.921,2.27 28.667,25.5 1.175,25.5 '
      />
    ),
    viewBox: '0 0 30 30'
  },
  circle: {
    shape: (
      <path d='M15,30A15,15,0,1,1,30,15,15,15,0,0,1,15,30ZM15,6.23A8.77,8.77,0,1,0,23.77,15,8.77,8.77,0,0,0,15,6.23Z' />
    ),
    viewBox: '0 0 30 30'
  },
  arrowUp: {
    shape: (
      <>
        <path d='M28.74,20.81H1.26a1.26,1.26,0,0,1-1-2L14.16.5a1.25,1.25,0,0,1,1-.5h0a1.24,1.24,0,0,1,1,.51L29.75,18.8a1.25,1.25,0,0,1-1,2ZM3.81,18.29H26.22L15.16,3.37Z' />{' '}
        <path d='M28.74,42H1.26a1.28,1.28,0,0,1-1.13-.71A1.26,1.26,0,0,1,.26,40l13.9-18.29a1.28,1.28,0,0,1,1-.5h0a1.24,1.24,0,0,1,1,.51L29.75,40a1.26,1.26,0,0,1,.12,1.32A1.28,1.28,0,0,1,28.74,42ZM3.81,39.47H26.22L15.16,24.55Z' />
      </>
    ),
    viewBox: '0 0 30 42'
  },
  upDown: {
    shape: (
      <>
        <path d='M28.74,44.58a1.28,1.28,0,0,1-1-.51L15.16,27.13l-12.89,17a1.26,1.26,0,1,1-2-1.53l13.9-18.29a1.34,1.34,0,0,1,1-.5,1.24,1.24,0,0,1,1,.51L29.75,42.56a1.27,1.27,0,0,1-1,2Z' />
        <path d='M14.83,20.82h0a1.28,1.28,0,0,1-1-.52L.25,2a1.27,1.27,0,0,1,2-1.51L14.84,17.45,27.73.5a1.26,1.26,0,0,1,2,1.53L15.84,20.32A1.28,1.28,0,0,1,14.83,20.82Z' />
      </>
    ),
    viewBox: '0 0 30 44.58'
  },
  box: {
    shape: (
      <path d='M28,2V28H2V2H28m.13-2H1.88A1.88,1.88,0,0,0,0,1.88V28.13A1.88,1.88,0,0,0,1.88,30H28.13A1.87,1.87,0,0,0,30,28.13V1.88A1.88,1.88,0,0,0,28.13,0Z' />
    ),
    viewBox: '0 0 30 30'
  },
  hexa: {
    shape: (
      <polygon
        strokeLinejoin='round'
        strokeMiterlimit='10'
        points='27.5,21.904 15,28.809  2.5,21.904 2.5,8.095 15,1.19 27.5,8.095 '
      />
    ),
    viewBox: '0 0 30 30'
  },
  cross: {
    shape: (
      <path
        strokeWidth='3px'
        d='M60.5,50l34.8-34.8c2.9-2.9,2.9-7.6,0-10.5c-2.9-2.9-7.6-2.9-10.5,0L50,39.5L15.2,4.7c-2.9-2.9-7.6-2.9-10.5,0    c-2.9,2.9-2.9,7.6,0,10.5L39.5,50L4.7,84.8c-2.9,2.9-2.9,7.6,0,10.5c1.4,1.4,3.3,2.2,5.2,2.2s3.8-0.7,5.2-2.2L50,60.5l34.8,34.8    c1.4,1.4,3.3,2.2,5.2,2.2c1.9,0,3.8-0.7,5.2-2.2c2.9-2.9,2.9-7.6,0-10.5L60.5,50z'
      />
    ),
    viewBox: '0 0 100 100'
  },
  code: {
    shape: (
      <>
        <path fill='none' d='M0 0h24v24H0V0z' />
        <path d='M9.4 16.6L4.8 12l4.6-4.6L8 6l-6 6 6 6 1.4-1.4zm5.2 0l4.6-4.6-4.6-4.6L16 6l6 6-6 6-1.4-1.4z' />
      </>
    ),
    viewBox: '0 0 24 24'
  },
  logo: {
    shape: (
      <g
        transform='translate(0.000000,1500.000000) scale(0.100000,-0.100000)'
        // fill='#000000'
        stroke='none'
      >
        <path
          d='M7105 14813 c-22 -1 -103 -7 -180 -13 -1249 -96 -2512 -538 -3560
-1244 -1635 -1101 -2739 -2823 -3080 -4801 -81 -470 -100 -712 -100 -1280 0
-490 3 -552 51 -935 163 -1309 686 -2552 1507 -3580 1291 -1615 3218 -2618
5305 -2760 198 -13 756 -13 954 0 1493 102 2869 640 4048 1586 517 414 1011
932 1381 1449 743 1037 1181 2153 1328 3385 41 340 46 438 46 885 -1 481 -9
615 -66 1009 -317 2216 -1650 4199 -3584 5331 -955 559 -2031 888 -3153 965
-136 9 -793 12 -897 3z m1020 -447 c1144 -113 2182 -480 3135 -1111 1710
-1131 2847 -2969 3074 -4970 64 -559 55 -1138 -25 -1705 -241 -1702 -1123
-3285 -2444 -4385 -1107 -922 -2432 -1451 -3892 -1555 -194 -14 -702 -14 -898
0 -1121 78 -2189 410 -3135 976 -882 527 -1618 1224 -2180 2063 -768 1149
-1178 2563 -1147 3961 7 305 18 454 53 740 186 1505 853 2881 1915 3950 1194
1202 2788 1928 4499 2049 191 14 856 5 1045 -13z'
        />
        <path
          d='M11245 10184 c-142 -20 -192 -30 -275 -56 -360 -114 -618 -365 -831
-806 -113 -233 -255 -606 -339 -888 l-43 -140 -91 135 c-453 674 -946 988
-1596 1018 -229 10 -515 -25 -790 -96 -367 -96 -857 -301 -1237 -518 l-120
-69 -7 535 c-8 595 -6 575 -76 612 -44 24 -103 24 -156 0 -35 -16 -82 -72
-318 -379 l-276 -360 -77 44 c-170 97 -350 143 -588 151 -261 8 -447 -30 -660
-137 -65 -32 -120 -58 -121 -57 -119 162 -542 702 -563 720 -36 30 -86 42
-139 33 -47 -7 -82 -33 -105 -77 -17 -32 -17 -91 -14 -1034 3 -873 6 -1013 20
-1103 20 -123 53 -267 78 -341 11 -30 19 -59 19 -65 0 -7 -20 -18 -45 -25
-184 -54 -382 -208 -469 -364 -192 -342 -111 -746 195 -979 158 -120 333 -181
634 -219 67 -9 997 -11 3595 -10 l3505 2 35 23 c21 14 44 41 55 66 18 39 18
52 7 238 -35 593 -175 1173 -413 1714 l-61 137 52 193 c58 218 169 560 255
783 229 599 487 894 862 987 121 30 312 30 433 0 362 -88 640 -351 732 -690
30 -112 31 -307 1 -418 -80 -293 -306 -565 -666 -799 -110 -71 -129 -99 -124
-178 6 -92 63 -152 146 -152 39 0 61 9 127 48 211 128 475 361 596 527 176
242 260 484 259 751 0 190 -27 327 -96 492 -187 443 -637 736 -1153 752 -70 2
-140 2 -157 -1z m-5648 -1639 c-4 -695 -8 -763 -51 -947 -24 -102 -73 -234
-122 -328 -195 -376 -600 -601 -1079 -599 -124 0 -168 5 -260 28 -22 5 -71 17
-110 25 -90 21 -271 110 -358 176 -218 166 -356 389 -432 701 -42 175 -46 270
-43 1008 l3 694 180 -233 c99 -129 195 -245 214 -257 24 -17 48 -23 86 -23 55
0 59 2 167 87 92 72 232 132 383 165 36 7 121 12 210 12 128 -1 164 -5 249
-28 129 -34 244 -91 331 -166 39 -33 85 -64 104 -70 48 -13 110 4 152 43 19
18 111 133 204 255 l170 222 3 -47 c2 -27 2 -349 -1 -718z m2691 557 c321 -70
564 -211 817 -474 454 -471 807 -1248 970 -2132 21 -117 38 -244 59 -438 l4
-38 -1580 0 -1581 0 6 78 c18 226 160 499 340 654 275 236 627 349 1187 379
158 8 212 15 235 28 35 19 75 92 75 136 -1 47 -35 112 -72 136 -31 20 -42 20
-218 15 -470 -14 -861 -108 -1167 -281 -396 -223 -649 -598 -695 -1032 l-12
-113 -1092 0 -1091 0 -6 93 c-5 78 -21 150 -51 218 -8 19 27 29 99 29 24 0 95
9 158 20 560 95 957 442 1142 995 71 214 93 370 103 750 l7 260 80 53 c490
323 1044 559 1520 648 185 34 298 43 485 39 136 -2 209 -9 278 -23z m-5154
-2158 c54 -80 187 -216 286 -291 106 -80 312 -182 450 -223 101 -30 107 -33
164 -93 91 -96 135 -202 120 -283 l-7 -36 -446 5 c-482 5 -556 11 -710 63
-217 73 -338 210 -349 397 -14 235 121 422 358 498 86 28 93 26 134 -37z'
        />
      </g>
    ),
    viewBox: '0 0 1500 1500'
  }
};

const SVG = ({ stroke, fill, width, icon, left, top, hiddenMobile }) => (
  <Wrapper
    viewBox={icons[icon].viewBox}
    stroke={stroke}
    fill={fill}
    svgWidth={twWidth[`${width}`]}
    left={left}
    top={top}
    hiddenMobile={hiddenMobile}
  >
    {icons[icon].shape}
  </Wrapper>
);

export default SVG;

SVG.propTypes = {
  stroke: PropTypes.string,
  fill: PropTypes.string,
  width: PropTypes.number,
  icon: PropTypes.oneOf(Object.keys(icons)).isRequired,
  left: PropTypes.string,
  top: PropTypes.string,
  hiddenMobile: PropTypes.bool
};

SVG.defaultProps = {
  stroke: 'transparent',
  width: 8,
  fill: 'none',
  left: '0%',
  top: '0%',
  hiddenMobile: false
};
