const tailwind = require('../tailwind');

module.exports = {
  pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "/portfolio"

  siteTitle: 'agalcalledjen Portfolio', // Navigation and Site Title
  siteTitleAlt: 'agalcalledjen', // Alternative Site title for SEO
  siteTitleShort: 'agalcalledjen', // short_name for manifest
  siteHeadline: 'Making mobile and web applications feel alive!', // Headline for schema.org JSONLD
  siteUrl: 'https://www.agalcalledjen.com', // Domain of your site. No trailing slash!
  siteLanguage: 'en', // Language Tag on <html> element
  siteLogo: '/logo.png', // Used for SEO and manifest
  siteDescription: 'agalcalledjen Portofolio',
  author: 'Jennifer Lam', // Author for schema.org JSONLD

  // siteFBAppID: '123456789', // Facebook App ID - Optional
  // userTwitter: '@user', // Twitter Username
  // ogSiteName: 'username', // Facebook Site Name
  // ogLanguage: 'en_US', // Facebook Language
  googleAnalyticsID: 'UA-139430787-1',

  // Manifest and Progress color
  themeColor: tailwind.colors.orange,
  backgroundColor: tailwind.colors.blue
};
