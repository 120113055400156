import React from 'react';
import PropTypes from 'prop-types';
import tw from 'tailwind.macro';
import styled from 'styled-components';
import { Divider } from '../elements/Dividers';
import Content from '../elements/Content';
import { UpDown, UpDownWide, waveAnimation } from '../styles/animations';
import { colors } from '../../tailwind';
import SVG from '../components/SVG';

const WaveWrapper = styled.div`
  ${tw`absolute pin-b w-full`};
  transform: matrix(1, 0, 0, -1, 0, 0);
`;

const InnerWave = styled.div`
  ${tw`relative h-full`};
  svg {
    width: 100%;
    height: 40vh;
  }
  path {
    ${waveAnimation('20s')};
  }
`;

const Contact = ({ children, offset }) => (
  <>
    <Divider
      fill='#e3d6ea'
      // fill='linear-gradient(to right, #e3d6ea 0%, #cba3e4 100%)'
      // bg='linear-gradient(to right, #e3d6ea 0%, #cba3e4 100%)'
      speed={0.2}
      offset={offset}
    >
      <WaveWrapper>
        <InnerWave>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 800 338.05'
            preserveAspectRatio='none'
            fill={colors.primary}
            // fill='linear-gradient(to right, #e3d6ea 0%, #cba3e4 100%)'
          >
            <path>
              <animate
                attributeName='d'
                values='M 0 100 Q 250 50 400 200 Q 550 350 800 300 L 800 0 L 0 0 L 0 100 Z;M 0 100 Q 200 150 400 200 Q 600 250 800 300 L 800 0 L 0 0 L 0 100 Z;M 0 100 Q 150 350 400 200 Q 650 50 800 300 L 800 0 L 0 0 L 0 100 Z'
                repeatCount='indefinite'
                dur='30s'
              />
            </path>
          </svg>
        </InnerWave>
      </WaveWrapper>
    </Divider>
    <Content speed={0.4} offset={offset}>
      {children}
    </Content>
    <Divider speed={0.1} offset={offset}>
      <UpDown>
        <SVG
          icon='code'
          hiddenMobile
          width={8}
          fill={colors['grey-lightest']}
          left='70%'
          top='20%'
        />
        <SVG
          icon='triangle'
          width={8}
          stroke={colors['grey-lightest']}
          left='25%'
          top='5%'
        />
      </UpDown>
      <UpDownWide>
        <SVG
          icon='triangle'
          width={12}
          stroke={colors.white}
          left='95%'
          top='50%'
        />
        <SVG icon='circle' width={6} fill={colors.white} left='85%' top='15%' />
        <SVG
          icon='code'
          hiddenMobile
          width={8}
          fill={colors['grey-lightest']}
          left='45%'
          top='10%'
        />
      </UpDownWide>
      <SVG icon='circle' width={6} fill={colors.white} left='4%' top='20%' />
      <SVG
        icon='circle'
        width={12}
        fill={colors['grey-lightest']}
        left='70%'
        top='60%'
      />
      <SVG
        icon='logo'
        width={12}
        fill={colors['grey-lightest']}
        left='20%'
        top='30%'
      />
      <SVG
        icon='hexa'
        width={8}
        stroke={colors['grey-lightest']}
        left='80%'
        top='70%'
      />
    </Divider>
  </>
);

export default Contact;

Contact.propTypes = {
  children: PropTypes.node.isRequired,
  offset: PropTypes.number.isRequired
};
